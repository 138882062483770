import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing:border-box;
    }

    html, body, #root{
        height: 100%;
        background-color:#fff;  
    }
    *, button, input{
       border: 0;
       outline: 0;
       font-family: arial;
    }

    button{
        cursor:pointer;
    }

#nav-toggle:checked + .sidebar .sidebar-brand .logimg {
display: none;
}

#nav-toggle:checked ~ .main-content .DivGrafico{
    
      transition: margin-left 300ms;
      padding-left: 16%;
 

  }

  main {
  margin-top: 50px;
  padding: 1.5rem 1.5rem;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;

}



#nav-toggle:checked ~ .main-content {
  margin-left: 70px;
}

.main-content {
  transition: margin-left 300ms;
  margin-left: 220px;
}

#nav-toggle:checked + .sidebar {
  width: 70px;
}

#nav-toggle:checked + .sidebar .side:hover .Legenda-side {
  display: inline;
}

#nav-toggle:checked + .sidebar .side-dropdown:hover .Legenda-Dropdown {
  display: inline;
}
#nav-toggle:checked + .sidebar .side-dropdown:hover .Legenda-Dropdown a {
  display: inline;
  list-style: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-left: -10px;
  padding: 5px;
 
}

#nav-toggle:checked + .sidebar .side-dropdown:hover .lab-Dropdown {
  font-size: 10px;
  display: inline;
  
}

#nav-toggle:checked + .sidebar i {
  display: none;
}

#nav-toggle:checked + .sidebar .lab-icone {
  display: none;
}

#nav-toggle:checked + .sidebar .nav__dropdown-content {
  display: none;
}

#nav-toggle:checked + .sidebar .ControleIcon:hover {
  -moz-transform: scale(2.2);
  -webkit-transform: scale(5.5);
  transform: scale(1.2);
}

#nav-toggle:checked + .sidebar li a {
  padding-left: 0rem;
  font-size: 20px;
}

#nav-toggle:checked + .Icon-drop {
  display: none;
}

#nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
#nav-toggle:checked + .sidebar li a span:last-child {
  display: none;
}

#nav-toggle:checked ~ .main-content header {
  width: calc(100% - 70px);
  left: 70px;
  transition: left 300ms;
}

@media only screen and (max-width: 768px) {

  
  #nav-toggle:checked ~ .main-content .DivGrafico{
    padding-left: 16%;
  }

  main {
    margin-top: 60px;
    margin-bottom: 50px;
  }

  #nav-toggle:checked + .sidebar .nav__dropdown-content {
    display: inline;
  }

  #nav-toggle:checked + .sidebar .lab-icone {
    display: inline;
  }

  #nav-toggle:checked + .sidebar .dropdown-background {
    display: inline;
  }

  #nav-toggle:checked + .sidebar i {
    display: inline;
  }
  #nav-toggle:checked + .sidebar .lab-icone {
    display: inline;
  }

  #nav-toggle:checked + .sidebar i {
    display: inline;
    margin-left: 55px;
    font-size: 13px;
  }

  #nav-toggle:checked + .sidebar .nav__dropdown-content {
    display: grid;
    row-gap: 0.1rem;
    padding: 0.2rem 1.5rem 0.2rem 1rem;
    cursor: pointer;
  }

  #nav-toggle:checked + .sidebar .side:hover .Legenda-side {
    display: none;
  }

  #nav-toggle:checked ~ .main-content {
    margin-left: 0px;
  }
  .search-wrapper {
    display: none;
  }

  .sidebar {
    left: -100% !important;
  }

  .main-content {
    width: 100%;
    margin-left: 0rem;
  }

  header {
    width: 100% !important;
    left: 0 !important;
  }

  #nav-toggle:checked + .sidebar {
    left: 0 !important;
    z-index: 100;
    width: 220px;
  }

  #nav-toggle:checked + .sidebar .sidebar-menu,
  #nav-toggle:checked + .sidebar li {
    text-align: left;
  }

  #nav-toggle:checked + .sidebar li a {
    padding-left: 1rem;
  }

  #nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
  #nav-toggle:checked + .sidebar li a span:last-child {
    display: inline;
  }

  #nav-toggle:checked ~ .main-content {
    padding-left: 0rem !important;
  }

  #nav-toggle:checked + .sidebar .side:hover .Legenda-side {
    display: none;
  }

  #nav-toggle:checked + .sidebar .side-dropdown:hover .Legenda-Dropdown {
    display: none;
  }

}

@media only screen and (max-width: 560px) {
  .cards {
    grid-template-columns: 100%;
  }

  .nome-list .span-nome{
font-size: 11px;
}

.nome-list .span-inf{
font-size: 11px;
}

.nome-list .span-inf2{
  margin-left: 0px;
  font-size: 11px;
  }
}
`;