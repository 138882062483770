import React from "react";

import * as C from "./styles";

const Resume = ({ income, expense, total }) => {
  return (
    <C.Container>
 
    </C.Container>
  );
};

export default Resume;
