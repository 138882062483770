import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Resume from "../../components/Resume";
import Form from "../../components/Form";
import './index.css'

const App = () => {
  const logo = require("../../components/img/now.png") as string;
  const {idlocal} = useParams();

  useEffect(() => {
    //console.log(idlocal)
  }, []);

  return (
    <>
      <Header />
      <Resume income={'income'} expense={'expense'} total={'total'} />
      <Form
       id={idlocal}
      />
      <div className="img-logo">
    <img className="logoNav" src={logo}  alt="logo"/>
    </div>
    </>
  );
};

export default App;
