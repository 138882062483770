import axios from 'axios';

const api = axios.create({
    baseURL: 'https://callback.adlim.com.br:3001/'
})
//https://callback.adlim.com.br/

export default api;



