import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import Pesquisa from "../pages/Pesquisa";

  export const Router = ()=>{
  
    return(
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Pesquisa/>}/>
        <Route path="/:idlocal" element={<Pesquisa/>}/>
        </Routes>
      </BrowserRouter>
    )
  }
export default Router
