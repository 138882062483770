
import React, { useState } from 'react'
import './index.css'

const Header = () => {
  return (
    <div className="Container">
      <div className="Header-title">
        <h1 className="Title">Pesquisa de Satisfação</h1>
      </div>
    </div>
  );
};

export default Header;
