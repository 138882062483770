
import React, { useState, useEffect } from 'react'
import { AxiosResponse } from "axios";
import "./index.css";
import { FcSearch } from "react-icons/fc";
import { BsFillEmojiLaughingFill } from "react-icons/bs";
import { BsFillEmojiNeutralFill } from "react-icons/bs";
import { BsFillEmojiFrownFill } from "react-icons/bs";
import { FcInspection } from "react-icons/fc";
import moment from 'moment';
import api from '../../Services/api'
import { AiFillInfoCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import {FaRegHandPointLeft}from "react-icons/fa";

function Chamados(props: any) {
  const [toggleState, setToggleState] = useState(1);
  const [perg1, setPerg1] = useState("");
  const [perg2, setPerg2] = useState("");
  const [perg3, setPerg3] = useState("");
  const [obs, setObs] = useState("");
  const { idlocal } = useParams();

  function DetalhesChamado() {
    setToggleState(4)

  }



  async function Concluir() {
    let idLocal = props.id

    const dados = {
      aval1: perg1,
      aval2: perg2,
      aval3: perg3,
      obs,
      idLocal
    }

    let ret: AxiosResponse;
    let valor: any;
    //pesquisa/insert

    try {
      ret = await api.post("pesquisa/insert", 'data=' + JSON.stringify({
        aval1: perg1,
        aval2: perg2,
        aval3: perg3,
        obs,
        idLocal
      }));
      setToggleState(5)
    } catch (e) {
      console.log(e)
      
    }

    const dateexec = moment().format('YYYY-MM-DD h:mm:ss');
    localStorage.setItem("execucao", dateexec.toString());
  }

  function tab1(tab: any, valor: any) {
    setToggleState(tab);
    setPerg1(valor);
  }

  function tab2(tab: any, valor: any) {
    setToggleState(tab);
    setPerg2(valor);
  }

  function tab3(tab: any, valor: any) {
    setToggleState(tab);
    setPerg3(valor);
  }

  useEffect(() => {

  //  if (props.id === undefined) {
   //   setToggleState(6)
   // } else {
    //  let dataExec = localStorage.getItem("execucao")

   // if (moment(dataExec) > moment().subtract(1, 'hours')) {
   //    setToggleState(5)
   //  } else {
    //   setToggleState(1)
    //  }
  //  
//}

  }, []);

  return (
    <div className="ContainerForm">

      <div className="container-tab-chamad">

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <h1 className="title-pergunta">1- O que você está achando do aroma do banheiro?</h1>
            <div className="ContainerForm-esc">
              <div className="card-escolha" onClick={() => tab1(2, 1)}>
                <BsFillEmojiLaughingFill className="icon-escolha-otimo" />
                <h1 className="lab-escolha">ÓTIMO</h1>
              </div>
              <div className="card-escolha" onClick={() => tab1(2, 2)}>
                <BsFillEmojiNeutralFill className="icon-escolha-regular" />
                <h1 className="lab-escolha">REGULAR</h1>
              </div>

              <div className="card-escolha" onClick={() => tab1(2, 3)}>
                <BsFillEmojiFrownFill className="icon-escolha-ruim" />
                <h1 className="lab-escolha">RUIM</h1>
              </div>
            </div>

          </div>




          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <h1 className="title-pergunta">2- O que você está achando da limpeza?</h1>
            <div className="ContainerForm-esc">
              <div className="card-escolha" onClick={() => tab2(4, 1)}>
                <BsFillEmojiLaughingFill className="icon-escolha-otimo" />
                <h1 className="lab-escolha">ÓTIMO</h1>
              </div>
              <div className="card-escolha" onClick={() => tab2(4, 2)}>
                <BsFillEmojiNeutralFill className="icon-escolha-regular" />
                <h1 className="lab-escolha">REGULAR</h1>
              </div>

              <div className="card-escolha" onClick={() => tab2(4, 3)}>
                <BsFillEmojiFrownFill className="icon-escolha-ruim" />
                <h1 className="lab-escolha">RUIM</h1>
              </div>
            </div>

          </div>
        </div>
        <div
          className={
            toggleState === 3 ? "content  active-content" : "content"
          }
        >
          <h1 className="title-pergunta">3- O que você está achando da reposição de material (papel higiênico e sabonete líquido)?</h1>
          <div className="ContainerForm-esc">
            <div className="card-escolha" onClick={() => tab3(4, 1)}>
              <BsFillEmojiLaughingFill className="icon-escolha-otimo" />
              <h1 className="lab-escolha">ÓTIMO</h1>
            </div>
            <div className="card-escolha" onClick={() => tab3(4, 2)}>
              <BsFillEmojiNeutralFill className="icon-escolha-regular" />
              <h1 className="lab-escolha">REGULAR</h1>
            </div>

            <div className="card-escolha" onClick={() => tab3(4, 3)}>
              <BsFillEmojiFrownFill className="icon-escolha-ruim" />
              <h1 className="lab-escolha">RUIM</h1>
            </div>
          </div>
        </div>

        <div
          className={
            toggleState === 4 ? "content-chamado  active-content" : "content"
          }
        >
          <h1 className="title-pergunta">Deixe um comentário com sugestões de melhorias sobre o nosso serviço.</h1>
          <div className="input-box-chamado-novo">
            <textarea
              onChange={text => setObs(text.target.value)}
            />
            <div className="div-btn">
              <button type="button" className='btn-concluir' onClick={Concluir}>Concluir</button>
            </div>
          </div>
        </div>


        <div
          className={
            toggleState === 5 ? "content-chamado  active-content" : "content"
          }
        >

          <div className="icone-concluir">

            <FcInspection className="icone-AgenOk" />

          </div>
          <div className="icone-concluir">
            <h1 className='lab-obg'>Obrigado! Estamos trabalhando para melhorar a cada dia.</h1>    
          </div>
          <div className="icone-concluir">
          <h1 className='lab-obg2'>Conheça Nossos Serviços</h1>
          </div>
          <div className="icone-concluir">
          <a className='lab-obg3' href='https://www.adlim.com.br/' >Clique aqui</a>      
          </div>
        </div>

        <div
          className={
            toggleState === 6 ? "content-chamado  active-content" : "content"
          }
        >

          <div className="icone-concluir">

            <AiFillInfoCircle className="icone-AgenOk" />

          </div>
          <div className="icone-concluir">
            <h1 className='lab-obg'>Faça a leitura do QRcode para iniciar a avaliação.</h1>

          </div>
        </div>


      </div>

    </div>



  );

}

export default Chamados;